import {  Text, Card, CardBody } from '@pancakeswap/uikit'
import styled from 'styled-components';

const StyledCard = styled(Card) <{ background: string; rotation?: string }>`
  height: fit-content;
  padding: 1px 1px 4px 1px;
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.backgroundAlt2};
  ${({ theme }) => theme.mediaQueries.md} {
    ${({ rotation }) => (rotation ? `transform: rotate(${rotation});` : '')}
  }
`

const StyledCardBody = styled(CardBody) <{ background: string; rotation?: string }>`
  background: ${({ theme }) => theme.colors.backgroundAlt2};
`

const ExchangeStatics: React.FC<{ headingText: string; bodyText: string; highlightColor: string }> = ({
  headingText,
  bodyText
}) => {
  const split = headingText.split(' ')
  const lastWord = split.pop()
  const remainingWords = split.slice(0, split.length).join(' ')

  return (
    <StyledCard>
      <StyledCardBody>
        <Text fontSize="25px" fontWeight="800" color='primary' style={{ marginBottom: "5px" }}>
          {remainingWords} {lastWord}
        </Text>
        <Text fontSize="15px" color="textSubtle">{bodyText}</Text>
      </StyledCardBody>
    </StyledCard>
  )
}

export default ExchangeStatics

import {Flex, Text, Card, CardBody , Box } from '@pancakeswap/uikit'
import styled from 'styled-components';


const StyledCard = styled(Card) <{ background: string; rotation?: string }>`
  height: fit-content;
  padding: 1px 1px 4px 1px;
  box-sizing: border-box;
  background : ${({ theme }) => theme.colors.backgroundAlt2}
  ${({ theme }) => theme.mediaQueries.md} {
    ${({ rotation }) => (rotation ? `transform: rotate(${rotation});` : '')}
  }
`

const StyledCardBody = styled(CardBody) <{ background: string; rotation?: string }>`
  background: ${({ theme }) => theme.colors.backgroundAlt2};
`

const EcooCard: React.FC<{ headingText: string; bodyText: string; highlightColor: string, image: any }> = ({
    headingText,
    bodyText,
    image
}) => {
    const split = headingText.split(' ')
    const lastWord = split.pop()
    const remainingWords = split.slice(0, split.length).join(' ')

    return (
        <StyledCard>
            <StyledCardBody>
                <Flex flexWrap="wrap" justifyContent="space-between">
                    <Box flex="1" width="80%">
                        <Text fontSize="25px" fontWeight="800" color='primary' style={{ marginBottom: "5px" }}>
                            {remainingWords} {lastWord}
                        </Text>
                        <Text fontSize="15px" color="textSubtle">{bodyText}</Text>
                    </Box>
                    <Box>
                        <img style={{ marginLeft: "10px" }} src={image} height="50px" width="50px" alt='nft' />
                    </Box>
                </Flex>
            </StyledCardBody>
        </StyledCard>
    )
}

export default EcooCard

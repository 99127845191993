/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import {
  Heading,
  Flex,
  Text,
  CommunityIcon,
  SwapIcon,
  Box
} from '@pancakeswap/uikit'
import { FACTORY_ADDRESS_MAP } from '@pancakeswap/sdk'
import { provider } from 'utils/wagmi'
import { useNetwork } from 'wagmi'
import { getChainId, ROUTER_ADDRESS } from 'config/constants/exchange'
import IPancakeRouter02ABI from 'config/abi/IPancakeRouter02.json'
import FactoryABI from 'config/abi/Factory.json'
import { getContract } from 'utils/contractHelpers'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import useIntersectionObserver from 'hooks/useIntersectionObserver'
import { IconCardData } from '../IconCard'
import GradientLogo from '../GradientLogoSvg'
import EcooCard from './EcooCard'
import ExchangeStatics from './ExchangeStatics';








const Stats = () => {
  const { t } = useTranslation()
  const { theme } = useTheme()
  const { observerRef } = useIntersectionObserver();
  const { chain } = useNetwork();
  const [totaltx, setTotaltx] = useState('0')
  const [totalpair, setTotalpair] = useState('0')

  useEffect(() => {
    async function fetch() {
      const routerContract = getContract(IPancakeRouter02ABI, ROUTER_ADDRESS[getChainId(chain)], provider(getChainId(chain)));
      const totalTx = await routerContract.totalTx();
      const factoryContract = getContract(FactoryABI, FACTORY_ADDRESS_MAP[getChainId(chain)], provider(getChainId(chain)));
      const totalPair = await factoryContract.allPairsLength();

      setTotaltx(totalTx)
      setTotalpair(totalPair)
    }

    fetch();
  }, [])


  const UsersCardData: IconCardData = {
    icon: <CommunityIcon color="primary" width="72px" />,
  }

  const TradesCardData: IconCardData = {
    icon: <SwapIcon color="primary" width="72px" />,
  }

  return (
    <>
      <Flex mb="48px" justifyContent="center" alignItems="center" flexDirection="column" ref={observerRef}>
        <GradientLogo height="48px" width="48px" mb="24px" />
        <Heading color='primary' textAlign="center" scale="xl">
          LuckySwap Ecosystem
        </Heading>
        <Text textAlign="center">
          Shaping the Future of Decentralized Trading:
        </Text>


        <Text textAlign="center" bold mb="32px">
          LuckyCoin Exchange Unstoppable Expansion
        </Text>
      </Flex>
      <Flex mt="48px" mb="48px" justifyContent="center" alignItems="center" flexDirection="column" ref={observerRef}>
        <Flex flexWrap="wrap" justifyContent="space-between">
          <Box width={["100%", null, "48%"]} mb={4}>

            <EcooCard
              image="/images/swap.png"
              headingText={t('Swap tokens')}
              bodyText="Trade any Binance chain (BEP-20) tokens on the platform with ease & fast, Low fees and secure."
              highlightColor={theme.colors.primary}
              {...UsersCardData}
            />
          </Box>
          <Box width={["100%", null, "48%"]} mb={4}>
            <EcooCard
              image="/images/pools.png"
              headingText={t('Liquidity Pools')}
              bodyText='After Connect.Your manage liquidity pools such as add liquidity or withdraw easily & securely.'
              highlightColor={theme.colors.primary}
            />
          </Box>
        </Flex>
        <Flex flexWrap="wrap" justifyContent="space-between">
          <Box width={["100%", null, "48%"]} mb={4}>
            <EcooCard
              image="/images/earn.png"
              headingText={t('Earn')}
              bodyText='Create passive income. It’s time to enjoy your life and let your money work for you every day.'
              highlightColor={theme.colors.primary}
            />
          </Box>
          <Box width={["100%", null, "48%"]} mb={4}>
            <EcooCard
              image="/images/nft.png"
              headingText={t('NFT')}
              bodyText='Create NFT on blockchain more 10k. by Mint, Stake, earn. Trade your NFT on marketplace platform.'
              highlightColor={theme.colors.primary}
            />
          </Box>
        </Flex>
      </Flex>

      <Flex mt="48px" mb="48px" justifyContent="center" alignItems="center" flexDirection="column" ref={observerRef}>
        <Heading color='primary' textAlign="center" scale="xl" mb="10px">
          Trade and earn
        </Heading>
        <Text textAlign="center" bold>
          on the growing Luckyswap platform.
        </Text>
        <Text textAlign="center" bold mb="25px">
          The ceaseless expansion of trading
        </Text>
      </Flex>

      <Flex mt="48px" flexWrap="wrap" style={{ textAlign: "center" }} justifyContent="space-around">

        <Box width={["100%", null, "40%"]}>
          <ExchangeStatics
            headingText={totaltx.toString()}
            bodyText="TOTAL TRANSACTIONS"
            highlightColor={theme.colors.primary}
            {...UsersCardData}
          />
        </Box>
        <Box width={["100%", null, "40%"]}>
          <ExchangeStatics
            headingText={totalpair.toString()}
            bodyText="TOTAL TRADING PAIRS"
            highlightColor={theme.colors.primary}
            {...UsersCardData}
          />
        </Box>

      </Flex>
    </>
  )
}

export default Stats
